<template>
  <b-container id="extracts" class="mb-5">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <h1>Extracts</h1>

    <b-form @submit.prevent="submitForm" class="reducer-filter mb-3">
      <!-- Dates filter -->
      <b-row>
        <b-col cols="12" lg="5">
          <b-form-group label="FROM LAST EXTRACT" label-for="startDate" class="mb-1">
            <b-input
              id="startDate"
              class="reducer-input"
              v-model="form.startDate"
              @input="validate"
              type="date"
              required
              :state="form.isValidDate"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="5" class="mt-2 mt-lg-0">
          <b-form-group label="TO LAST EXTRACT" label-for="endDate" class="mb-1">
            <b-input
              :state="form.isValidDate"
              id="endDate"
              class="reducer-input"
              v-model="form.endDate"
              @input="validate"
              type="date"
              required
            />
          </b-form-group>
        </b-col>
        <!-- Desktop form buttons -->
        <b-col class="d-none d-lg-flex align-items-end mb-2">
          <b-button type="submit" :disabled="form.busy" variant="primary" class="reducer-btn">
            <div v-if="form.busy & !pagination.busy">
              <Busy :size="1.3" :margin="0" class="px-3" />
            </div>
            <div v-else>Search</div>
          </b-button>
          <b-button class="ml-2" @click.stop="clearSearch">Clear</b-button>
        </b-col>

        <b-col cols="12">
          <b-form-invalid-feedback :state="form.isValidDate" class="">
            End date must be after start date.
          </b-form-invalid-feedback>
        </b-col>
        <b-col cols="12" class="mt-1 mb-2" order="2">
          <b-badge class="pointer" @click="setExtractDate()">TODAY</b-badge>
          <b-badge class="ml-2 pointer" @click="setExtractDate(0)">THIS MONTH</b-badge>
          <b-badge class="ml-2 pointer" @click="setExtractDate(1)">LAST MONTH</b-badge>
        </b-col>
      </b-row>

      <!-- Platforms and report states filters -->
      <b-row>
        <b-col cols="12" lg="4" class="mt-2">
          <b-form-group label="PLATFORMS FILTER" label-for="platforms-select">
            <multiselect
              id="platforms-select"
              v-model="filter.platforms"
              :options="filter.platformOptions"
              multiple
              taggable
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6" class="mt-2">
          <b-form-group label="STATES FILTER" label-for="states-select">
            <multiselect
              id="states-select"
              v-model="filter.states"
              :options="filter.stateOptions"
              multiple
              taggable
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="EXCLUDE" label-for="exclusions-checkboxes">
            <div id="exclusions-checkboxes" class="">
              <b-checkbox class="mr-3" v-model="filter.excludeReducerEmails"
                >REDUCER EMAILS</b-checkbox
              >
              <b-checkbox v-model="filter.excludeDemoOrgs">DEMO COMPANIES</b-checkbox>
              <b-checkbox
                v-model="filter.excludeAdminExtracts"
                v-b-popover.hover="'Filter only applies to extracts after 16th August 2021'"
                >ADMIN EXTRACTS</b-checkbox
              >
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Mobile view form buttons -->
      <div class="my-2 text-right d-lg-none">
        <b-button type="submit" :disabled="form.busy" variant="primary" class="reducer-btn">
          <div v-if="form.busy & !pagination.busy">
            <Busy :size="1.3" :margin="0" class="px-3" />
          </div>
          <div v-else>Search</div>
        </b-button>
        <b-button class="ml-2" @click.stop="clearSearch">Clear</b-button>
      </div>

      <!-- Sorting and Grouping -->
      <b-row class="filter-border">
        <b-col cols="12" lg="5" class="mt-2 mt-lg-0">
          <b-form-group label="SORT DIRECTION" label-for="sort-direction-select" class="mb-0">
            <b-select
              id="sort-direction-select"
              class="reducer-select"
              v-model="filter.sortDirection"
              :options="filter.sortDirectionOptions"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="5" class="mt-2 mt-lg-0">
          <b-form-group label="SORT BY" label-for="sort-by-select" class="mb-0">
            <b-select
              id="sort-by-select"
              class="reducer-select"
              v-model="filter.sortBy"
              :options="filter.sortByOptions"
            />
          </b-form-group>
        </b-col>
        <b-col class="mt-3 text-right text-lg-left mt-lg-auto">
          <b-form-group class="mb-0">
            <b-checkbox v-model="groupByUser" switch class="mb-2">GROUP BY DOMAIN</b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Summary -->
    <b-row v-if="showResults" class="extracts-summary mb-3">
      <b-col cols="12" class="d-flex align-items-center pb-1">
        <span class="text-muted text-uppercase small"
          >{{ summary.startDate }} - {{ summary.endDate }}</span
        >
      </b-col>
      <b-col cols="12" md="4">
        <div class="summary-line">
          <label>Total extracts</label>
          <span>{{ summary.totalExtracts }}</span>
        </div>
        <div class="summary-line">
          <label>Distinct organisations</label>
          <span>{{ summary.distinctOrganisations }}</span>
        </div>
      </b-col>
      <b-col cols="12" md="4">
        <div class="summary-line">
          <label>Distinct users</label>
          <span>{{ summary.distinctUsers }}</span>
        </div>
        <div class="summary-line">
          <label>Distinct non-admin users</label>
          <span>{{ summary.distinctNonAdminUsers }}</span>
        </div>
      </b-col>
      <b-col cols="12" md="4">
        <div class="summary-line">
          <label>Average spotlight score</label>
          <SpotlightScoreBadge
            :score="{ totalScore: summary.averageScore }"
            id="summary-average"
            disabled
          />
        </div>
        <div class="summary-line">
          <label>Estimated commission</label>
          <span>{{ formatCurrency(summary.estimatedCommission) }}</span>
        </div>
      </b-col>
    </b-row>

    <!-- Results -->
    <div v-if="showResults">
      <b-pagination
        v-if="extracts.length > 0 && !(groupByUser && groupedExtracts)"
        v-model="currentPage"
        class="reducer-pagination float-right mb-2"
        :total-rows="pagination.resultsCount"
        :per-page="pagination.perPage"
      />
      <!-- Group By User -->
      <div v-if="groupByUser && groupedExtracts">
        <div class="my-2 ml-auto score-select">
          <b-select
            class="reducer-select"
            v-model="spotlightScoreSort"
            :options="spotlightScoreSortOptions"
          />
        </div>
        <b-list-group v-for="(userExtracts, domain) in groupedExtracts" :key="domain">
          <b-list-group-item
            @click="showUserGroup[domain] = showUserGroup[domain] == true ? false : true"
            href="#"
            class="d-flex justify-content-between align-items-center"
          >
            {{ domain }}
            <div>
              <b-badge
                >{{ userExtracts.length }} extract{{ userExtracts.length == 1 ? "" : "s" }}
              </b-badge>
              <SpotlightScoreBadge
                :score="{ totalScore: spotlightScoreStats[domain].averageScore }"
                :commission="spotlightScoreStats[domain].estimatedCommission"
                :id="domain"
                class="ml-2"
              />
            </div>
          </b-list-group-item>
          <b-collapse v-model="showUserGroup[domain]">
            <b-table
              show-empty
              :items="userExtracts"
              :fields="extractFields"
              :busy="pagination.busy"
            >
              <template v-slot:table-busy>
                <Busy primary text="Loading..." />
              </template>
              <template #cell(spotlightSummary)="row">
                <SpotlightScoreBadge
                  :score="row.value.score"
                  :id="row.item.organisationId + row.item.startTime"
                  :org-id="row.item.organisationId"
                  :spotlight-summary="row.item.spotlightSummary"
                  area-breakdown
                />
              </template>
            </b-table>
          </b-collapse>
        </b-list-group>
      </div>
      <!-- Regular Table -->
      <b-table
        v-else
        show-empty
        id="extracts"
        :items="extracts"
        :fields="extractFields"
        :per-page="pagination.perPage"
        :current-page="1"
        :busy="pagination.busy"
        responsive
      >
        <template #table-busy>
          <Busy primary text="Loading..." />
        </template>
        <template #cell(spotlightSummary)="row">
          <SpotlightScoreBadge
            :score="row.value.score"
            :id="row.item.organisationId + row.item.startTime"
            :org-id="row.item.organisationId"
            :spotlight-summary="row.item.spotlightSummary"
          />
        </template>
      </b-table>
      <b-pagination
        align="right"
        v-model="currentPage"
        :total-rows="pagination.resultsCount"
        :per-page="pagination.perPage"
        class="mt-3 reducer-pagination"
        v-if="extracts.length > 0 && !(groupByUser && groupedExtracts)"
        :first-text="'\u00AB'"
        :prev-text="'\u2039'"
        :next-text="'\u203A'"
        :last-text="'\u00BB'"
        :ellipsis-text="'\u2026'"
      ></b-pagination>
    </div>
  </b-container>
</template>

<script>
import FormatHelper from "@/helper/formathelper";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import Busy from "@/components/Busy";
import SpotlightScoreBadge from "@/components/spotlight/SpotlightScoreBadge";
import moment from "moment";

export default {
  name: "Extracts",
  components: {
    Busy,
    SpotlightScoreBadge
  },
  data() {
    return {
      title: "Extracts",
      currentPage: 1,
      showResults: false,
      groupByUser: false,
      showUserGroup: {},
      summary: [],
      extracts: [],
      extractFields: [
        { key: "orgName" },
        { key: "userEmail", label: "Connected User" },
        { key: "startedByUserEmail", label: "Run By" },
        { key: "state" },
        { key: "startTime", label: "Start", formatter: this.formatDate },
        { key: "endTime", label: "End", formatter: this.formatDate },
        { key: "type" },
        { key: "spotlightSummary", label: "Score", class: "text-center" }
      ],
      form: {
        busy: false,
        isValidDate: null,
        startDate: null,
        endDate: null
      },
      filter: {
        show: false,
        sortDirection: "descending",
        sortBy: "startTime",
        platforms: ["xero", "quickBooks"],
        states: ["COMPLETED", "LIMITED"],
        excludeReducerEmails: true,
        excludeDemoOrgs: true,
        excludeAdminExtracts: true,
        stateOptions: [
          "COMPLETED",
          "ERRORED",
          "CREATED",
          "RUNNING",
          "TOKEN_EXPIRED",
          "TIMED_OUT",
          "LIMITED",
          "MANUAL"
        ],
        currentFilter: {},
        platformOptions: ["xero", "quickBooks", "manual"],
        sortDirectionOptions: [
          { text: "Ascending", value: "ascending" },
          { text: "Descending", value: "descending" }
        ],
        sortByOptions: [
          { text: "Organisation name", value: "orgName" },
          { text: "Extract start time", value: "startTime" },
          { text: "Email domain", value: "emailDomain" }
        ]
      },
      pagination: {
        busy: false,
        perPage: 70,
        resultsCount: null
      },

      spotlightScoreSort: null,
      spotlightScoreSortOptions: [
        { text: "Sort by score", value: null },
        { text: "Highest score first", value: "descending" },
        { text: "Lowest score first", value: "ascending" }
      ],
      spotlightScoreStats: {}
    };
  },
  computed: {
    groupedExtracts() {
      if (this.groupByUser) {
        let groupedExtracts = {};

        this.extracts.forEach((e) => {
          if (e.userEmail) {
            if (!e.domain) {
              e.domain = this.getDomain(e.userEmail);
            }
            if (!groupedExtracts[e.domain]) {
              groupedExtracts[e.domain] = [];
            }
            groupedExtracts[e.domain].push(e);
          }
          this.addScoreStat(e);
        });

        if (this.spotlightScoreSort) {
          groupedExtracts = Object.fromEntries(
            Object.entries(groupedExtracts).sort((a, b) => {
              if (this.spotlightScoreSort == "ascending") {
                return parseInt(this.spotlightScoreStats[a[0]].averageScore) <
                  parseInt(this.spotlightScoreStats[b[0]].averageScore)
                  ? -1
                  : 1;
              } else {
                return parseInt(this.spotlightScoreStats[a[0]].averageScore) <
                  parseInt(this.spotlightScoreStats[b[0]].averageScore)
                  ? 1
                  : -1;
              }
            })
          );
        }
        return groupedExtracts;
      }
      return null;
    }
  },
  watch: {
    currentPage: function () {
      // check if current page is not reset on form submit
      if (!this.form.busy) {
        this.pagination.busy = true;
        this.fetchData();
      }
    }
  },
  methods: {
    formatDate: FormatHelper.formatDateTimeToMin,
    formatCurrency: FormatHelper.formatPoundToDisplay,
    validate() {
      if (this.form.startDate == null || this.form.endDate == null) {
        this.form.isValidDate = null;
      } else if (new Date(this.form.startDate) > new Date(this.form.endDate)) {
        this.form.isValidDate = false;
      } else {
        this.form.isValidDate = true;
      }
    },
    async submitForm() {
      if (this.form.isValidDate) {
        this.form.busy = true;
        this.showResults = false;
        this.extracts = this.summary = [];
        this.currentPage = 1;
        this.currentFilter = this.filter;
        await this.fetchData();
        this.showResults = true;
      }
    },
    async fetchData() {
      let client = await ApiHelper.http();
      let query =
        `startDate=${this.form.startDate}T00:00:00.001Z` +
        `&endDate=${this.form.endDate}T23:59:59.599Z` +
        `&itemsPerPage=${this.groupByUser ? ApiHelper.ALL_ITEMS : this.pagination.perPage}` +
        `&offset=${this.pagination.perPage * (this.currentPage - 1)}` +
        `&sortBy=${this.currentFilter.sortBy}` +
        `&sortDirection=${this.currentFilter.sortDirection}` +
        `&excludeDemoOrgs=${this.currentFilter.excludeDemoOrgs}` +
        `&excludeAdminExtracts=${this.currentFilter.excludeAdminExtracts}` +
        `&excludeReducerEmails=${this.currentFilter.excludeReducerEmails}`;

      if (this.currentFilter.states.length != this.currentFilter.stateOptions.length) {
        this.currentFilter.states.forEach((state) => (query += "&states=" + state));
      }

      if (this.currentFilter.platforms.length != this.currentFilter.platformOptions.length) {
        this.currentFilter.platforms.forEach((platform) => (query += "&platforms=" + platform));
      }

      await client
        .get(`${ApiHelper.endPoint()}extracts?` + query)
        .then((response) => {
          this.spotlightScoreStats = {};
          this.pagination.resultsCount = response.data.pager.totalCount;
          this.extracts = response.data.extracts;

          Console.log(
            `Fetched extracts ${this.pagination.perPage * (this.currentPage - 1)} to ${
              this.pagination.perPage * (this.currentPage - 1) + this.extracts.length
            } out of ${this.pagination.resultsCount}. Currently on page ${this.currentPage}.`
          );
          Console.log(response);

          if (this.form.busy) {
            // if data was fetched using search
            this.summary = {
              startDate: this.form.startDate,
              endDate: this.form.endDate,
              totalExtracts: this.pagination.resultsCount,
              distinctUsers: response.data.distinctUsers,
              distinctNonAdminUsers: response.data.distinctNonAdminUsers,
              distinctOrganisations: response.data.distinctOrganisations,
              averageScore: response.data.averageSpotlightScore,
              estimatedCommission: response.data.estimatedCommission
            };
          }
        })
        .catch((e) => {
          this.form.busy = this.pagination.busy = false;
          Console.error(e);
          EventBus.$emit("show-toast", {
            message: "There was a problem fetching the results.",
            variant: "danger"
          });
        })
        .finally(() => {
          this.form.busy = this.pagination.busy = false;
        });
    },

    clearSearch() {
      this.form.isValidDate = this.form.startDate = this.form.endDate = null;
      this.filter.excludeReducerEmails = this.filter.excludeDemoOrgs = this.groupByUser = false;
      this.filter.platforms = this.filter.states = this.extracts = [];
      this.summary = {};
    },

    setExtractDate(monthsBack) {
      if (monthsBack == undefined) {
        this.form.startDate = moment().format("YYYY-MM-DD");
      } else {
        this.form.startDate = moment().subtract(monthsBack, "months").format("YYYY-MM-01");
      }

      this.form.endDate = moment().format(monthsBack > 0 ? "YYYY-MM-01" : "YYYY-MM-DD");

      this.validate();
    },

    addScoreStat(extract) {
      if (!this.spotlightScoreStats[extract.domain]) {
        this.spotlightScoreStats[extract.domain] = {
          totalScore: 0,
          extractsCount: 0,
          averageScore: 0,
          estimatedCommission: 0
        };
      }
      const valOrZero = (e, key) =>
        e.spotlightSummary && e.spotlightSummary.score ? e.spotlightSummary.score[key] : 0;
      this.spotlightScoreStats[extract.domain].extractsCount++;
      this.spotlightScoreStats[extract.domain].totalScore += valOrZero(extract, "totalScore");
      this.spotlightScoreStats[extract.domain].estimatedCommission += valOrZero(
        extract,
        "estimatedCommission"
      );
      this.spotlightScoreStats[extract.domain].averageScore = (
        this.spotlightScoreStats[extract.domain].totalScore /
        this.spotlightScoreStats[extract.domain].extractsCount
      ).toFixed();
    },

    getDomain(email) {
      const commonDomains = [
        "@gmail.",
        "@hotmail.",
        "@yahoo.",
        "@googlemail.",
        "@aol.",
        "@msn.",
        "@protonmail.",
        "@live.",
        "@gmx.",
        "@free.",
        "@web.",
        "@outlook.",
        "@icloud.",
        "@mail.",
        "@sky.",
        "@btinternet.",
        "@btopenworld.",
        "@btconnect.",
        "@talktalk."
      ];
      if (email) {
        for (const d of commonDomains) {
          if (email.includes(d)) return email;
        }
        return email.split("@")[1];
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#extracts {
  @include badge-styles;
  max-width: 1400px;
  .extracts-summary {
    .summary-line {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #666a6e47;
      margin-bottom: 0.5rem;
      padding-bottom: 3px;
    }
    label {
      margin-bottom: 0;
    }
    label,
    h5 {
      color: $color-font-headings;
    }
  }
  .score-select {
    width: 15rem;
  }
}
</style>
